import React, { useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { t, Trans } from "@lingui/macro"

import { Title, Button, Section, Box, Text, Input } from "../../components/Core"

import imgOval from "../../assets/image/svg/Net_Pfade_Light_reverse_v2 Kopie.svg"
// import svgCurve from "../../assets/image/svg/l1-curve-cta.svg"

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`

// const RightCard = styled(Box)`
//   position: absolute;
//   top: 0;
//   right: -275px;
// `

const Newsletter = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        if (data.result === "success") {
          setSuccess(true)
          if (typeof window !== "undefined") {
            window.plausible("newsletter_signup", {
              props: { path: document.location.pathname },
            })
          }
        } else {
          setSuccess(false)
          setError(data.msg)
        }
      })
  }

  return (
    <>
      {/* <!-- CTA section --> */}
      <Section bg="dark" className="position-relative" id="newsletter_signup_footer">
        <LeftCard
          data-aos="fade-right"
          data-aos-duration="750"
          data-aos-once="true">
          <img src={imgOval} alt="" className="img-fluid" />
        </LeftCard>
        {/*<RightCard>
          <img src={svgCurve} alt="" className="img-fluid" />
        </RightCard>*/}
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" xl="9">
              <Title color="light" className="text-center">
                <Trans>
                  Bleiben Sie auf dem Laufenden über neue KI-Technologien
                </Trans>
              </Title>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="7">
              <Box mb={5} className="text-center">
                <Text color="light" opacity={0.9}>
                  <Trans>
                    Melden Sie sich bei unserem Newsletter an, um über die
                    aktuellen Entwicklungen von Künstlicher Intelligenz zu
                    erfahren.
                  </Trans>
                </Text>
                <Text color="light" opacity={0.9}>
                  <Trans>Kein SPAM! Sie können sich jederzeit abmelden.</Trans>
                </Text>
              </Box>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="5">
              <form>
                <Box mb={3}>
                  <Input
                    type="email"
                    placeholder={t`Ihre E-Mail`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>

                <Button
                  width="100%"
                  type="submit"
                  borderRadius={10}
                  onClick={handleSubmit}>
                  <Trans>Anmelden</Trans>
                </Button>
                <Text color="light">
                  {success && (
                    <>
                      <span role="img" aria-label="celebrate">
                        🎉
                      </span>
                      <Trans>
                        Vielen Dank, eine Einladung per Mail ist unterwegs,
                        bitte noch bestätigen.
                      </Trans>
                    </>
                  )}
                  {error && (
                    <>
                      <span role="img" aria-label="celebrate">
                        😞
                      </span>
                      <div dangerouslySetInnerHTML={{ __html: error }} />
                    </>
                  )}
                </Text>
              </form>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Newsletter
